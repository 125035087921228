import request from "@/utils/request";

// 查询
export function recongnizerImg(data) {
  return request({
    url: "/system/tencentColudSDK/recongnizerImg",
    method: "post",
    data
  });
}

// 人脸截取
export function operateImage(data) {
  return request({
    url: "/system/detectFace/operateImage",
    method: "post",
    data
  });
}
