<template>
  <div class="mainPoster">
      <div class="content">
        <div class="posterMessage">
          <div class="topLeft"></div>
          <div class="topRight"></div>
          <div class="bottomLeft"></div>
          <div class="bottomRight"></div>
          <div class="CharacterRecognition">
            <p v-for="item in options" :key="item">{{ item }}</p>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { recongnizerImg } from "@/api/poster.js";
import uploadFile from "@/components/UploadFile";
export default {
  data() {
    return {
      form: [],
    };
  },
  props:{
    options:{
      type:Array
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "jpg";
      const extension2 = fileName === "png";
      const extension3 = fileName === "pdf";
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是pdf、xls、xlsx格式!",
          type: "warning",
        });
        // this.clearFiles();
        this.dataForm.fileString = "";
      }
    },
    //文件上传接口
    async upload(params) {
      const form = new FormData();
      form.append("file", params.file);
      const res = await recongnizerImg(form);
      if (res.code == 200) {
        this.form = res.data.advertiseOCRResponse.textDetections.map((item) => {
          return item.detectedText;
        });
      }
      this.form2.push({
        name: params.file.name,
        fileName: res.data.fileAddr[0],
        url: res.data.fileLook[0],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  // width: 100%;
  flex-direction: column;
  // justify-content: center;
  height: 100%;
  // width: 20%;
  // overflow: auto;
  .poster {
    width: 300px;
    // flex: 1;
    height: 50%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    position: relative;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    .picture {
      width: 95%;
      height: 97%;
      padding-top: 2%;
      overflow: auto;
      img {
        width: 100%;
      }
    }
  }
  .posterMessage {
    width: 400px;
    height: 200px;
    // flex: 1;
    // height: 300px;
    // overflow: auto;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    position: relative;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    p {
      color: #1f212b;
      font-size: 15px;
      padding: 10px 0 2px 10px;
      border-bottom: 1px solid #dbdbdb;
      margin: 0 5px 0 5px;
      span {
        border-bottom: 2px solid #1e9fff;
      }
    }
    .CharacterRecognition {
      // height: 100%;
      overflow: auto;
      // flex: 1;
    }
  }
}
.topTip {
  margin-top: 10px;
  margin-bottom: 5px;
  .yuanjiao {
    border: 2px solid #1e9fff;
    height: 20px;
    background-color: #1e9fff;
    border-radius: 5px;
  }
  .basicMessage {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 9px;
  }
}
/deep/ .el-card {
  height: 100%;
  // width: 100%;
}
/deep/ .el-card__body {
  height: 100%;
  // width: 100%;
}
// .buttonSearch{
//   flex: 1;
// }
// div圆角
.topLeft {
  width: 10px;
  height: 10px;
  border-top: 1px solid #1e9fff;
  border-left: 1px solid #1e9fff;
  border-top-left-radius: 5px;
  position: absolute;
  top: -1px;
  left: -1px;
}
.topRight {
  width: 10px;
  height: 10px;
  border-top: 1px solid #1e9fff;
  border-right: 1px solid #1e9fff;
  border-top-right-radius: 5px;
  position: absolute;
  top: -1px;
  right: -1px;
}
.bottomLeft {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #1e9fff;
  border-left: 1px solid #1e9fff;
  border-bottom-left-radius: 5px;
  position: absolute;
  bottom: -1px;
  left: -1px;
}
.bottomRight {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #1e9fff;
  border-right: 1px solid #1e9fff;
  border-bottom-right-radius: 5px;
  position: absolute;
  bottom: -1px;
  right: -1px;
}

// 编辑海报
.brn_input {
  position: absolute;
  top: 10px;
  right: 8%;
  display: flex;
  /deep/ .el-button{
    background-color: #fff;
    color: #363E4D;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    text-align: center;
  }
}
</style>