<template>
  <div>
    <div class="index researchProject">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="openAdd()">+添加赞助商</el-button>
        <!-- <el-button type="primary" @click="editSponsor()"
          >+添加赞助商</el-button
        > -->
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="sponsorName"
          label="赞助商名称"
        ></el-table-column>
        <el-table-column label="所在地区">
          <template v-slot="{ row }">
            {{ row.sponsorCountry ? row.sponsorCountry : "" }}
            {{ row.sponsorProvince ? "-" + row.sponsorProvince : "" }}
            {{ row.sponsorCity ? "-" + row.sponsorCity : "" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sponsorType"
          label="赞助商类型"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="sponsorType" label="赞助商类型" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.sponsorType" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="sponsorIntro" label="赞助商简介">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.sponsorIntro"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.sponsorIntro !== null &&
                      scope.row.sponsorIntro !== ''
                    "
                  >
                    {{ scope.row.sponsorIntro.substring(0, 20) }}
                    <span v-if="scope.row.sponsorIntro.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="personType"
      />
      <el-dialog
        title="添加赞助商信息"
        :visible.sync="dialogFormVisible"
        width="50%"
        center
        @close="handleClose"
      >
        <div class="formFlex">
          <el-form :model="form" :rules="formRules" ref="ruleForm">
            <el-form-item
              label="赞助商名称:"
              label-width="120px"
              prop="sponsorName"
            >
              <el-input
                v-model="form.sponsorName"
                style="width: 430px"
              ></el-input>
            </el-form-item>
            <el-form-item label="赞助商类型:" label-width="120px">
              <dic-checkbox-group
                :code.sync="form.sponsorType"
                type-code="029"
                ref="editin"
              ></dic-checkbox-group>
            </el-form-item>
            <el-form-item label="所在地区:" label-width="120px">
              <cascader-area
                :country="form.sponsorCountry"
                :province="form.sponsorProvince"
                :city="form.sponsorCity"
                @searchCountry="searchCountry"
                @searchProvince="searchProvince"
                @searchCity="searchCity"
                ref="child"
              />
            </el-form-item>
            <el-form-item label="赞助商简介:" label-width="120px">
              <el-input
                type="textarea"
                v-model="form.sponsorIntro"
                :autosize="{ minRows: 4, maxRows: 20 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="识别结果:" label-width="120px" v-if="options">
              <activity-result :options="options" />
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑赞助商信息"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
        width="50%"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item
            label="赞助商名称:"
            label-width="120px"
            prop="sponsorName"
          >
            <el-input
              v-model="editform.sponsorName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="赞助商类型:" label-width="120px">
            <dic-checkbox-group
              :code.sync="editform.sponsorType"
              type-code="029"
              ref="editin"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="所在地区:" label-width="120px">
            <cascader-area
              :country="editform.sponsorCountry"
              :province="editform.sponsorProvince"
              :city="editform.sponsorCity"
              @searchCountry="searchCountry1"
              @searchProvince="searchProvince1"
              @searchCity="searchCity1"
              ref="child"
            />
          </el-form-item>
          <el-form-item label="赞助商简介:" label-width="120px">
            <el-input
              type="textarea"
              v-model="editform.sponsorIntro"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="识别结果:" label-width="120px" v-if="options">
            <activity-result :options="options" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="cancelItem" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import activityResult from "@/components/activityResult.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getSponsor,
  addSponsor,
  updateSponsor,
  deleteSponsor,
} from "@/api/activity.js";
import { pagination } from "@/mixins/pagination";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroup,
    activityResult,
  },
  data() {
    return {
      index: 0,
      posterShow: null,
      form: {
        sponsorName: "", //赞助商名称
        sponsorType: [], //赞助商类型
        sponsorIntro: "", //赞助商简介
        sponsorCountry: "", //所在国家
        sponsorProvince: "", //所在省份
        sponsorCity: "", //所在城市
      },
      formRules: {
        sponsorName: [
          { required: true, message: "请输入赞助商名称", trigger: "blur" },
        ],
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      form2: [],
      options: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: {
        sponsorName: "", //赞助商名称
        sponsorType: [], //赞助商类型
        sponsorIntro: "", //赞助商简介
        sponsorCountry: "", //所在国家
        sponsorProvince: "", //所在省份
        sponsorCity: "", //所在城市
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    // 编辑数据的唯一id
    personData: {
      type: String,
    },
    personType: {
      type: String,
    },
    // 新增数据的唯一id
    activityId: {
      type: Number,
    },
    // form: {
    //   type:ListType
    // },
  },
  created() {
    if (this.personType) {
      this.search();
    }
    this.options = JSON.parse(sessionStorage.getItem("poster"));
  },
  methods: {
    openAdd() {
      this.dialogFormVisible = true;
      this.options = JSON.parse(sessionStorage.getItem("poster"));
    },
    searchCountry(val) {
      this.form.sponsorCountry = val;
    },
    searchProvince(val) {
      this.form.sponsorProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.sponsorCity = val;
      }
    },
    searchCountry1(val) {
      this.editform.sponsorCountry = val;
    },
    searchProvince1(val) {
      this.editform.sponsorProvince = val;
    },
    searchCity1(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editform.sponsorCity = val;
      }
    },
    // 清空表单
    deleteForm() {
      this.form = {
        sponsorName: "", //赞助商名称
        sponsorType: [], //赞助商类型
        sponsorIntro: "", //赞助商简介
        sponsorCountry: "", //所在国家
        sponsorProvince: "", //所在省份
        sponsorCity: "", //所在城市
      };
      this.editform = {
        sponsorName: "", //赞助商名称
        sponsorType: [], //赞助商类型
        sponsorIntro: "", //赞助商简介
        sponsorCountry: "", //所在国家
        sponsorProvince: "", //所在省份
        sponsorCity: "", //所在城市
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.activityId) {
        const dataPerson = Number(this.personData);
        this.queryInfo.id = dataPerson ? dataPerson : this.activityId;
      }
      const res = await getSponsor(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      // 是编辑就能新增
      // if (this.personType) {
      if (this.personType || this.activityId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const dataPerson = Number(this.personData);
            Object.assign(this.form, {
              Authorization: this.Authorization,
              activityId: dataPerson ? dataPerson : this.activityId,
            });
            const res = await addSponsor(this.form);
            if (res.code === 200) {
              this.dialogFormVisible = false;
              this.$message.success("新增成功");
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        // 新增只能添加假数据
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.activityId) {
            const dataPerson = Number(this.personData);
            const deleteForm = {
              sponsorId: data.id,
              activityId: dataPerson ? dataPerson : this.activityId,
            };
            const res = await deleteSponsor(deleteForm);

            if (res.code == 200) {
              this.$message.success("删除成功");
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.options = JSON.parse(sessionStorage.getItem("poster"));
      if (this.$refs.editin) {
        this.$refs.editin.getDicData();
      }
      if (this.$refs.child) {
        this.$refs.child.search();
      }
      this.index = index1;
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
      if (this.personType || this.activityId) {
        if (this.editform.sponsorType) {
          this.editform.sponsorType = this.editform.sponsorType.split(",");
        } else {
          this.editform.sponsorType = [];
        }
      }
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType || this.activityId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await updateSponsor(this.editform);
            if (res.code == 200) {
              this.$message.success("修改成功");
              this.deleteForm();
              this.search();
              this.dialogFormVisible1 = false;
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.form2[this.index] = this.editform;
            this.dialogFormVisible1 = false;
          }
        });
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
      this.$refs.child.clear();
      this.$refs.editin.clear();
    },
    cancelItem() {
      this.$router.go(-1);
    },
    // 跳转到新增编辑页面
    editSponsor() {
      this.$router.push({
        name: "EditSponsorInfo",
        query: {
          personType: 1,
          // id: row.id,
        },
      });
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("researchProject")[0].style.height =
        document.body.clientHeight - 185 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.researchProject {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
// .formFlex{
//   display: ;
// }
</style>
