<template>
  <el-upload action :on-remove="handleRemove" :http-request="upload" :file-list="explainFileList">
    <el-button size="small" type="primary">点击上传</el-button>
  </el-upload>
</template>

<script>
import { uploadFile } from '@/api/common'
export default {
  props: {
    lookUrl: {
      type: String,
    },
    saveUrl: {
      type: String,
    },
  },

  data() {
    return {
      fileList: [],
      // fileArray: [],
      explainFileList: [],
    }
  },

  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  watch: {
    lookUrl: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateExplainFileList()
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    updateExplainFileList() {
      if (this.lookUrl) {
        let name = this.lookUrl.substring(this.lookUrl.lastIndexOf('/') + 1)

        name = name.split('?')[0]

        this.explainFileList.push({
          name,
          url: this.lookUrl,
        })
      }
    },
    handleRemove() {
      this.explainImgList = []

    },
    async upload(file) {
      this.explainFileList = []
      const form = new FormData()

      form.append('files', file.file)

      form.append('Authorization', this.Authorization)

      const res = await uploadFile(form)

      if (res.code === 200) {
        this.$emit('update:lookUrl', res.data.fileLook[0])

        this.$emit('update:saveUrl', res.data.fileAddr[0])
      }
    },
    clear() {
      this.explainFileList = []
    },
  },
}
</script>

<style>
</style>
