<template>
  <div class="contentPoster">
    <poster-list
      class="poster"
      @activityPosterUrl="activityPosterUrl"
      :posterData="enterTalentsInfo"
      v-if="posterType"
    />
    <div class="posterMessage">
      <el-card>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <basic-message
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              :personData="personData"
              ref="personMessage"
            ></basic-message>
          </el-tab-pane>
          <el-tab-pane label="赞助方" name="second">
            <sponsor-info
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              :personData="personData"
              :activityId="activityId"
              ref="sponsorInfo"
            ></sponsor-info>
          </el-tab-pane>
          <el-tab-pane label="主办方信息" name="third">
            <organizer-info
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              :personData="personData"
              :activityId="activityId"
              ref="organizerInfo"
            ></organizer-info>
          </el-tab-pane>
          <el-tab-pane label="嘉宾信息" name="fourth">
            <guest-info
              :editTalentInfo="enterTalentsInfo"
              :personType="personType"
              :personData="personData"
              :activityId="activityId"
              ref="guestInfo"
            ></guest-info>
          </el-tab-pane>
          <el-tab-pane label="标签信息" name="five">
            <labelInfo ref="labelInfo"></labelInfo>
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="six">
            <labelManagement
              ref="labelManagement"
              :activityId="activityId"
              :personData="personData"
            ></labelManagement>
          </el-tab-pane>
          <el-tab-pane label="半自动化标签" name="seven">
            <robotizationlabel
              ref="robotizationlabel"
              :activityId="activityId"
              :personData="personData"
            ></robotizationlabel>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <div
        style="height: 50px; background: #fff;"
        v-if="this.activeName == 'seven'"
      ></div>
      <!-- v-if="!personType" -->
      <div class="bottom" v-if="activeName !== 'seven'">
        <el-button type="primary" @click="saveItem">保存</el-button>
        <el-button @click="cancelItem">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import posterList from "@/components/poster.vue";
import basicMessage from "./editMessage/basicMessage";
import organizerInfo from "./editMessage/organizerInfo";
import guestInfo from "./editMessage/guestInfo";
import sponsorInfo from "./editMessage/sponsorInfo";
import UploadImage from "@/components/UploadImage.vue";
// import Poster from "../../../../components/poster.vue";
import labelInfo from "./editMessage/labelInfo.vue";
import labelManagement from "./editMessage/labelManagement.vue";
import robotizationlabel from "./editMessage/robotizationlabel.vue";

// 新增基本信息 / 查询单个基本信息 / 编辑基本信息
// 新增标签信息 / 查询标签信息 / 编辑标签信息
// 手动打标签编辑更新 / 手动打标签新增
import {
  addActivity,
  ActivityOne,
  updateActivityOne,
  insertActivityLabel,
  selectActivityLabelDetail,
  updateActivityLabel,
  updateActivityLabelManual,
  insertActivityLabelManual
} from "@/api/activity.js";
export default {
  name: "editTalentXS",
  components: {
    basicMessage,
    organizerInfo,
    guestInfo,
    sponsorInfo,
    UploadImage,
    posterList,
    labelInfo,
    labelManagement,
    robotizationlabel
  },
  // 控制card高度
  // mounted() {
  //   if (this.personType) {
  //     // document.querySelector('.el-tabs__content').style.height =
  //     // document.body.clientHeight - 310 + 'px'
  //     document.querySelector(".el-tabs__content").style.minHeight =
  //       document.body.clientHeight - 240 + "px";
  //   } else {
  //     document.querySelector(".el-tabs__content").style.minHeight =
  //       document.body.clientHeight - 240 + "px";
  //   }
  // },
  computed: {
    // 数据唯一id(编辑带的)
    personData() {
      return this.$route.query.id;
    },
    // '' 新增 1 编辑
    personType() {
      return this.$route.query.personType;
    },
    posterType() {
      return this.$route.query.posterType;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    }
  },
  created() {
    if (this.personType) {
      this.selectActivityLabelDetail();
      setTimeout(() => {
        this.isShow = true;
      }, 200);
      this.ActivityOnes();
    } else {
      this.isShow = true;
    }
  },
  mounted() {
    this.$refs.personMessage.activityInformations = this.enterTalentsInfo.activityInformations;
  },
  data() {
    return {
      activeName: "first",
      activityId: null, // 新增基本信息的唯一id
      isShowInput: false,
      isShow: false,
      // (基本信息)表单校验信息
      enterTalentsInfo: {
        activityName: "", //活动名称
        activityImageLook: [], //活动图片查看
        activityImage: [], //活动图片
        shareImage: [], //活动分享图
        shareImageImageLook: [], //活动分享图查看
        activityPostersLook: [], //活动海报查看
        activityPosters: [], //活动海报
        activityBrief: "", //一句话
        beginTime: "", //开始时间
        endTime: "", //结束时间
        activityQuota: "", //活动名额
        activitySource: "", //活动来源
        activityType: "", //活动类别
        activityTypes: [], //活动类别二级
        costCategories: "", //费用类别
        theCharges: "", //收费金额
        activityMode: "", //活动方式
        activityLink: "", //活动链接
        activityCountry: "", //举办国家
        activityProvince: "", //举办省
        activityCity: "", //举办城市
        address: "", //详细地址
        activityIntro: "", //活动简介
        //附加信息
        activityInformations: [
          {
            ticketName: "", //票名
            ticketNumber: "", //票数
            ticketPrice: "", //票价
            isSingle: "", //是否单人单张
            remarks: "", //备注
            activityId: "" //主键id
          }
        ], //附加信息
        // -----------------------------------

        activityIndustry: [], //行业类别
        activityField: [], //领域类别
        activityTech: [], //技术类别
        createTime: "", //创建人名字
        updateName: "", //修改人名字
        updateTime: "", //修改人时间
        keyWord: "", //关键词
        fieldChildrenId: [], //领域子模块
        techChildrenId: [], //技术子模块
        industryChildrenId: [], //行业子模块
        activityPosterText: [], //活动海报识别内容

      },
      form: []
    };
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "six") {
          this.$refs.labelManagement.getBylabelId();
        }
      }
    },
    // 监听活动票种
    "enterTalentsInfo.activityInformations": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.$refs.personMessage) {
          this.$refs.personMessage.activityInformations = this.enterTalentsInfo.activityInformations;
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 5 && this.id) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存
    async save(res, path) {
      if (this.enterTalentsInfo.industryChildrenId) {
        this.enterTalentsInfo.activityIndustry = this.enterTalentsInfo.activityIndustry.concat(
          this.enterTalentsInfo.industryChildrenId
        );
      }
      if (this.enterTalentsInfo.techChildrenId) {
        this.enterTalentsInfo.activityTech = this.enterTalentsInfo.activityTech.concat(
          this.enterTalentsInfo.techChildrenId
        );
      }
      if (this.enterTalentsInfo.fieldChildrenId) {
        this.enterTalentsInfo.activityField = this.enterTalentsInfo.activityField.concat(
          this.enterTalentsInfo.fieldChildrenId
        );
      }
      // 拿到基本信息的活动票种数组
      this.enterTalentsInfo.activityInformations = this.$refs.personMessage.activityInformations;

      this.enterTalentsInfo.activityImage = this.$refs.personMessage.activityImage;
      this.enterTalentsInfo.activityImageLook = this.$refs.personMessage.activityImageLook;
      this.enterTalentsInfo.shareImage = this.$refs.personMessage.shareImage;
      this.enterTalentsInfo.shareImageImageLook = this.$refs.personMessage.shareImageImageLook;
      this.enterTalentsInfo.activityPosters = this.$refs.personMessage.activityPosters;
      this.enterTalentsInfo.activityPostersLook = this.$refs.personMessage.activityPostersLook;

      var allData = {
        activityDto: this.enterTalentsInfo,
        guestDto: this.$refs.guestInfo.form2,
        organizerDto: this.$refs.organizerInfo.form2,
        sponsorDto: this.$refs.sponsorInfo.form2
      };

      // 新增
      if (this.personType || this.activityId) {
        console.log(this.enterTalentsInfo, "this.enterTalentsInfo父组件");
        res = await updateActivityOne(this.enterTalentsInfo);
      } else {
        res = await addActivity(allData);
        // res = await updateActivityOne(allData);
      }
      if (res.code == 200) {
        this.$message.success(`${this.personType ? "编辑" : "新增"}成功`);
        // 赋值新增的唯一id
        if (res.data !== "修改成功" && res.data !== null) {
          this.activityId = res.data;
          this.activeName = path;
        } else {
          this.activeName = path;
        }
        // this.activeName = "second";
        sessionStorage.removeItem("poster");
      } else {
        `${this.personType ? "编辑" : "新增"}失败`;
        // this.activeName = "second";
      }
    },
    // 保存全部信息
    saveItem() {
      Object.assign(this.enterTalentsInfo, {
        Authorization: this.Authorization
      });
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async valid => {
              if (valid) {
                this.save(res, "second");
              }
            }
          );

          break;
        case "second":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async valid => {
              if (valid) {
                // 编辑
                if (this.personType || this.activityId) {
                  this.activeName = "third";
                } else {
                  this.save(res, "third");
                }
              }
            }
          );
          break;
        case "third":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async valid => {
              if (valid) {
                // 编辑
                if (this.personType || this.activityId) {
                  this.activeName = "fourth";
                } else {
                  this.save(res, "fourth");
                }
              }
            }
          );
          break;
        case "fourth":
          this.$refs.personMessage.$refs.editTalentInfo.validate(
            async valid => {
              if (valid) {
                // 编辑
                if (this.personType || this.activityId) {
                  this.activeName = "five";
                } else {
                  this.save(res, "five");
                }
              }
            }
          );
          break;
        case "five":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.activityLabelParam.activityKeywordAddParamList.forEach(
            item => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let activityKeywordLabelList = [];
              // 遍历我添加的数组
              item.activityKeywordLabelList.forEach(child => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach(item1 => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach(name => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0
                      };
                      activityKeywordLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                activityKeywordLabelList: activityKeywordLabelList
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let activityLabelParam = {
            keywordVersion: this.$refs.labelInfo.activityLabelParam
              .keywordVersion, //关键字版本
            activityId: this.activityId ? this.activityId : this.personData,
            activityKeywordAddParamList: arr
          };
          // 判断基本信息是否保存，是否有id

          if (!this.personType) {
            if (!this.activityId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(async valid => {
                if (valid) {
                  res = await insertActivityLabel(activityLabelParam);
                  if (res.code == 200) {
                    this.$message.success("新增成功!");
                    this.activeName = "six";
                  } else {
                    this.$message.error("新增失败，请稍候重试!");
                  }
                }
              });
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async valid => {
              if (valid) {
                res = await updateActivityLabel(activityLabelParam);
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "six";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "six":
          // 判断是否保存基本信息，是否有id
          if (!this.personType || !this.activityId) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach(el => {
              if (el.labelparam.labelNameList) {
                if (el.labelparam.labelNameList.length > 0) {
                  el.labelparam.activityId = this.activityId
                    ? this.activityId
                    : this.personData;
                  query.push(el.labelparam);
                }
              }
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertActivityLabelManual(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "seven";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updateActivityLabelManual(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "seven";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          } else {
            this.$message.error("请先保存基本信息的数据再进行下一步操作！");
          }

          break;
        case "seven":
          break;
        default:
          break;
      }
    },
    // 活动标签信息详情
    async selectActivityLabelDetail() {
      const res = await selectActivityLabelDetail({
        activityId: this.activityId ? this.activityId : this.personData
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.activityKeywordAddParamList) {
          this.$refs.labelInfo.activityLabelParam = {
            activityId: "", // 案例主键
            keywordVersion: "", // 关键字版本
            // type: null,
            activityKeywordAddParamList: [
              {
                activityKeywordLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null // 上级标签
                  }
                ],
                keyword: "", // 关键词
                keywordDescribe: "" // 关键词数据
              }
            ]
          };
        } else {
          if (!this.$refs.labelInfo.activityLabelParam) {
            return;
          } else {
            this.$refs.labelInfo.activityLabelParam = res.data;
            // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
            res.data.activityKeywordAddParamList.forEach(item => {
              if (item.activityKeywordLabelList) {
                item.activityKeywordLabelList.forEach(child => {
                  child.labelName =
                    child.groupLabelName + "/" + child.labelName;
                });
              }
            });
          }
        }
      }
    },
    // 判断是否为字符串
    formatString(val) {
      if (!val) {
        return [];
      } else {
        if (val.length > 1) {
          return val.split(",");
        } else {
          return val;
        }
      }
    },
    // 返回
    cancelItem() {
      this.$router.push({
        path: "/exercise/activeData"
      });
      sessionStorage.removeItem("poster");
    },
    editClick() {
      this.isShowInput = true;
    },
    // 查看单个数据
    async ActivityOnes() {
      const data = Number(this.personData);
      const res = await ActivityOne({ id: data });
      if (res.code == 200) {
        // this.$message.success("查询成功");
        this.enterTalentsInfo = res.data;
        // 拿到基本信息的活动票种数组返回的数据，赋值给子组件的活动票种数据
        this.$set(this, "enterTalentsInfo", res.data);

        // 如果活动类别返回null
        if (!res.data.activityTypes) {
          this.enterTalentsInfo.activityTypes = [];
        }

        if (!res.data.activityInformations) {
          const activityInformations = [
            {
              ticketName: "", //票名
              ticketNumber: "", //票数
              ticketPrice: "", //票价
              isSingle: "", //是否单人单张
              remarks: "", //备注
              activityId: "" //主键id
            }
          ];
          this.$set(
            this.enterTalentsInfo,
            "activityInformations",
            activityInformations
          );
        }

        this.enterTalentsInfo.activityIndustry = this.formatString(
          this.enterTalentsInfo.activityIndustry
        );
        // this.enterTalentsInfo.activityType = this.formatString(
        //   this.enterTalentsInfo.activityType
        // );
        this.enterTalentsInfo.activityField = this.formatString(
          this.enterTalentsInfo.activityField
        );
        this.enterTalentsInfo.activityTech = this.formatString(
          this.enterTalentsInfo.activityTech
        );
        this.$refs.personMessage.activityImage = res.data.activityImage
        this.$refs.personMessage.activityImageLook = res.data.activityImageLook
        this.$refs.personMessage.shareImage = res.data.shareImage
        this.$refs.personMessage.shareImageImageLook = res.data.shareImageImageLook
        this.$refs.personMessage.activityPosters = res.data.activityPosters
        this.$refs.personMessage.activityPostersLook = res.data.activityPostersLook
        // this.enterTalentsInfo.activityImage = this.formatString(
        //   this.enterTalentsInfo.activityImage
        // );
        // this.enterTalentsInfo.activityImageLook = this.enterTalentsInfo.solutionImg;
        // 活动海报识别内容
        if (this.enterTalentsInfo.activityPosterText.length > 0) {
          sessionStorage.setItem(
            "poster",
            JSON.stringify(this.enterTalentsInfo.activityPosterText)
          );
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    activityPosterUrl(data) {
      Object.assign(this.enterTalentsInfo, data);
      // this.enterTalentsInfo.activityPosterUrl = data
    }

    // -----------之前的保存方法
    // savezq() {
    //   // Object.assign(this.enterTalentsInfo, {
    //   //   Authorization: this.Authorization,
    //   // });
    //   // let res;
    //   // this.$refs.personMessage.$refs.editTalentInfo.validate(async (valid) => {
    //   //   //async
    //   //   if (valid) {
    //   //     if (this.enterTalentsInfo.industryChildrenId) {
    //   //       this.enterTalentsInfo.activityIndustry =
    //   //         this.enterTalentsInfo.activityIndustry.concat(
    //   //           this.enterTalentsInfo.industryChildrenId
    //   //         );
    //   //     }
    //   //     if (this.enterTalentsInfo.techChildrenId) {
    //   //       this.enterTalentsInfo.activityTech =
    //   //         this.enterTalentsInfo.activityTech.concat(
    //   //           this.enterTalentsInfo.techChildrenId
    //   //         );
    //   //     }
    //   //     if (this.enterTalentsInfo.fieldChildrenId) {
    //   //       this.enterTalentsInfo.activityField =
    //   //         this.enterTalentsInfo.activityField.concat(
    //   //           this.enterTalentsInfo.fieldChildrenId
    //   //         );
    //   //     }
    //   //     var allData = {
    //   //       activityDto: this.enterTalentsInfo,
    //   //       guestDto: this.$refs.guestInfo.form2,
    //   //       organizerDto: this.$refs.organizerInfo.form2,
    //   //       sponsorDto: this.$refs.sponsorInfo.form2,
    //   //     };
    //   //     const res = await addActivity(allData);
    //   //     if (res.code == 200) {
    //   //       this.$message.success("新增成功");
    //   //       this.$router.go(-1);
    //   //       sessionStorage.removeItem("poster");
    //   //     } else {
    //   //       this.$message.error("新增失败");
    //   //     }
    //   //   }
    //   // });
    // },
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-tabs__content {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}

/deep/ .el-card__body {
  padding: 0px 20px 0px 20px;
  position: relative;
}

/deep/ .jr-iconjr-icon-edit1:hover {
  color: #4d77ff;
  cursor: pointer;
}
.contentPoster {
  display: flex;
  justify-content: space-around;
  // height: 100%;
  .poster {
    // width: 20%;
    // flex: 1;
    margin-right: 10px;
    // height: 100%;
  }
  .posterMessage {
    width: 100%;
    flex: 1;
  }
}
</style>
