<template>
  <div>
    <div class="index researchProject1">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="openAdd()">+添加主办方</el-button>
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="organizerName"
          label="主办方名称"
        ></el-table-column>
        <el-table-column label="所在地区">
          <template v-slot="{ row }">
            {{ row.organizerCountry ? row.organizerCountry : "" }}
            {{ row.organizerProvince ? "-" + row.organizerProvince : "" }}
            {{ row.organizerCity ? "-" + row.organizerCity : "" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="organizerType"
          label="主办方类型"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="organizerType" label="主办方类型" v-else>
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.organizerType" :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="organizerCategories"
          label="主办方类别"
          v-if="personType"
        ></el-table-column>
        <el-table-column prop="organizerCategories" label="主办方类别" v-else>
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.organizerCategories"
              :key="index"
              >{{ item }}&nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="organizerIntro" label="主办方简介">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.organizerIntro"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.organizerIntro !== null &&
                      scope.row.organizerIntro !== ''
                    "
                  >
                    {{ scope.row.organizerIntro.substring(0, 20) }}
                    <span v-if="scope.row.organizerIntro.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="personType"
      />
      <el-dialog
        title="添加主办方信息"
        :visible.sync="dialogFormVisible"
        width="50%"
        center
        @close="handleClose"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item
            label="主办方名称:"
            label-width="120px"
            prop="organizerName"
          >
            <el-input
              v-model="form.organizerName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="主办方类型:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.organizerType"
              type-code="029"
              ref="editin"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="主办方类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="form.organizerCategories"
              type-code="030"
              ref="editin1"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="所在地区:" label-width="120px">
            <cascader-area
              :country="form.organizerCountry"
              :province="form.organizerProvince"
              :city="form.organizerCity"
              @searchCountry="searchCountry"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              ref="child"
            />
          </el-form-item>
          <el-form-item label="主办方简介:" label-width="120px">
            <el-input
              type="textarea"
              v-model="form.organizerIntro"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="识别结果:" label-width="120px" v-if="options">
            <activity-result :options="options" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑主办方信息"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
        width="50%"
      >
        <el-form :model="editform" :rules="formRules" ref="editruleForm">
          <el-form-item
            label="赞助商名称:"
            label-width="120px"
            prop="organizerName"
          >
            <el-input
              v-model="editform.organizerName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="主办方类型:" label-width="120px">
            <dic-checkbox-group
              :code.sync="editform.organizerType"
              type-code="029"
              ref="editin"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="主办方类别:" label-width="120px">
            <dic-checkbox-group
              :code.sync="editform.organizerCategories"
              type-code="030"
              ref="editin1"
            ></dic-checkbox-group>
          </el-form-item>
          <el-form-item label="所在地区:" label-width="120px">
            <cascader-area
              :country="editform.organizerCountry"
              :province="editform.organizerProvince"
              :city="editform.organizerCity"
              @searchCountry="searchCountry1"
              @searchProvince="searchProvince1"
              @searchCity="searchCity1"
              ref="child"
            />
          </el-form-item>
          <el-form-item label="主办方简介:" label-width="120px">
            <el-input
              type="textarea"
              v-model="editform.organizerIntro"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="识别结果:" label-width="120px" v-if="options">
            <activity-result :options="options" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('editruleForm')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('editruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
      <el-button @click="cancelItem" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import activityResult from "@/components/activityResult.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import {
  getOrganizer,
  addOrganizer,
  updateOrganizer,
  deleteOrganizer,
} from "@/api/activity.js";
import { pagination } from "@/mixins/pagination";
import CascaderArea from "@/components/CascaderArea.vue";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    CascaderArea,
    DicCheckboxGroup,
    activityResult,
  },
  data() {
    return {
      index: 0,
      options: [],
      form: {
        organizerName: "", //主办方名称
        organizerType: [], //主办方类型
        organizerCategories: [], //主办方类别
        organizerIntro: "", //主办方简介
        organizerCountry: "", //所在国家
        organizerProvince: "", //所在省份
        organizerCity: "", //所在城市
      },
      formRules: {
        organizerName: [
          { required: true, message: "请输入主办方名称", trigger: "blur" },
        ],
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      form2: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: {
        organizerName: "", //主办方名称
        organizerType: [], //主办方类型
        organizerCategories: [], //主办方类别
        organizerIntro: "", //赞助商简介
        organizerCountry: "", //所在国家
        organizerProvince: "", //所在省份
        organizerCity: "", //所在城市
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    // 编辑数据唯一id
    personData: {
      type: String,
    },
    // 0 新增 1 编辑
    personType: {
      type: String,
    },
    // 新增数据唯一id
    activityId: {
      type: Number,
    },
  },
  created() {
    if (this.personType) {
      this.search();
    }
    this.options = JSON.parse(sessionStorage.getItem("poster"));
  },
  methods: {
    searchCountry(val) {
      this.form.organizerCountry = val;
    },
    searchProvince(val) {
      this.form.organizerProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.form.organizerCity = val;
      }
    },
    searchCountry1(val) {
      this.editform.organizerCountry = val;
    },
    searchProvince1(val) {
      this.editform.organizerProvince = val;
    },
    searchCity1(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editform.organizerCity = val;
      }
    },
    // 清空表单
    deleteForm() {
      (this.form = {
        organizerName: "", //主办方名称
        organizerType: [], //主办方类型
        organizerCategories: [], //主办方类别
        organizerIntro: "", //赞助商简介
        organizerCountry: "", //所在国家
        organizerProvince: "", //所在省份
        organizerCity: "", //所在城市
      }),
        (this.editform = {
          organizerName: "", //主办方名称
          organizerType: [], //主办方类型
          organizerCategories: [], //主办方类别
          organizerIntro: "", //赞助商简介
          organizerCountry: "", //所在国家
          organizerProvince: "", //所在省份
          organizerCity: "", //所在城市
        }),
        (this.dialogFormVisible = false);
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.activityId) {
        const dataPerson = Number(this.personData);
        this.queryInfo.id = dataPerson ? dataPerson : this.activityId;
      }
      const res = await getOrganizer(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      if (this.personType || this.activityId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const dataPerson = Number(this.personData);
            Object.assign(this.form, {
              Authorization: this.Authorization,
              activityId: dataPerson ? dataPerson : this.activityId,
            });
            const res = await addOrganizer(this.form);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.dialogFormVisible = false;
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
        });
      }
    },
    openAdd() {
      this.dialogFormVisible = true;
      this.options = JSON.parse(sessionStorage.getItem("poster"));
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.activityId) {
            const dataPerson = Number(this.personData);

            const deleteForm = {
              organizerId: data.id,
              activityId: dataPerson ? dataPerson : this.activityId,
            };
            const res = await deleteOrganizer(deleteForm);

            if (res.code == 200) {
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.options = JSON.parse(sessionStorage.getItem("poster"));
      if (this.$refs.editin) {
        this.$refs.editin.getDicData();
      }
      if (this.$refs.editin1) {
        this.$refs.editin1.getDicData();
      }
      if (this.$refs.child) {
        this.$refs.child.search();
      }
      this.index = index1;
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      if (this.personType || this.activityId) {
        if (this.editform.organizerCategories) {
          this.editform.organizerCategories =
            this.editform.organizerCategories.split(",");
        } else {
          this.editform.organizerCategories = [];
        }
        if (this.editform.organizerType) {
          this.editform.organizerType = this.editform.organizerType.split(",");
        } else {
          this.editform.organizerType = [];
        }
      }
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
    },
    // 修改工作经历
    editEx(formName) {
      if (this.personType || this.activityId) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await updateOrganizer(this.editform);
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.deleteForm();
              this.search();
            }
          }
        });
      } else {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.form2[this.index] = this.editform;
            this.dialogFormVisible1 = false;
          }
        });
      }
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
      this.$refs.child.clear();
      this.$refs.editin.clear();
      this.$refs.editin1.clear();
    },
    cancelItem() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("researchProject1")[0].style.height =
        document.body.clientHeight - 185 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.researchProject1 {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>
