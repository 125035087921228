<template>
  <div>
    <div class="personMessage">
      <el-form
        :model="editTalentInfo"
        ref="editTalentInfo"
        :rules="ruleFormrules"
        label-width="100px"
      >
        <el-form-item label="活动名称:" prop="activityName" label-width="100px">
          <el-input
            v-model="editTalentInfo.activityName"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动图片:">
          <UpLoadImage
            @upLoadImgList="lookClick"
            @upOldLoadImgList="saveClick"
            :upLoadImgList.sync="activityImage"
            :upOldLoadImgList.sync="activityImageLook"
          />
        </el-form-item>
        <el-form-item label="一句话:" prop="activityBrief" label-width="100px">
          <el-input
            v-model="editTalentInfo.activityBrief"
            style="width: 50%"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间:" label-width="100px" prop="beginTime">
          <el-date-picker
            v-model="editTalentInfo.beginTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="选择开始时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="editTalentInfo.endTime"
            type="datetime"
            placeholder="选择结束时间"
            value-format="yyyy-MM-dd HH:mm"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动名额:" label-width="100px">
          <el-input
            style="width: 50%"
            v-model="editTalentInfo.activityQuota"
            maxlength="9"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="活动来源:" label-width="100px">
          <el-input
            style="width: 50%"
            v-model="editTalentInfo.activitySource"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动类别:" label-width="100px">
          <el-radio v-model="editTalentInfo.activityType" label="平台活动"
            >平台活动</el-radio
          >
          <el-radio v-model="editTalentInfo.activityType" label="渠道活动"
            >渠道活动</el-radio
          >
          <!-- {{ editTalentInfo.activityTypes + "---" }} -->
          <el-checkbox-group
            v-model="editTalentInfo.activityTypes"
            v-show="editTalentInfo.activityType"
          >
            <el-checkbox label="技术类"></el-checkbox>
            <el-checkbox label="产业类"></el-checkbox>
            <el-checkbox label="其他"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <!-- <el-checkbox-group
            v-model="editTalentInfo.activityTypes"
            v-show="editTalentInfo.activityType"
          >
            <dic-checkbox-group
              :code.sync="editTalentInfo.activityTypes"
              type-code="027"
            />
          </el-checkbox-group> -->
        <!-- <el-form-item label="活动类别:" prop="activityType" label-width="100px">
          <DicCheckboxGroupMy
            :code.sync="editTalentInfo.activityType"
            tag="活动"
            @updateCode="updateCode"
          />
        </el-form-item> -->
        <el-form-item label="费用类别:" label-width="100px">
          <el-radio-group v-model="editTalentInfo.costCategories">
            <dic-radio-group
              :code.sync="editTalentInfo.costCategories"
              type-code="028"
            />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="收费金额:" label-width="100px">
          <el-input
            style="width: 50%"
            v-model="editTalentInfo.theCharges"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动方式:" label-width="100px">
          <el-radio-group v-model="editTalentInfo.activityMode">
            <el-radio label="线上"></el-radio>
            <el-radio label="线下"></el-radio>
            <el-radio label="线上+线下"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="活动链接:"
          label-width="100px"
          prop="cooperationStatus"
        >
          <!-- <el-input
            style="width: 465px"
            v-model="editTalentInfo.activityLink"
          ></el-input> -->
          <el-link
            :href="editTalentInfo.activityLink"
            target="_blank"
            :underline="false"
            >{{ editTalentInfo.activityLink }}</el-link
          >
          <i
            class="jr-iconjr-icon-edit1"
            style="margin-left: 24px"
            @click="isLinkShow = true"
          ></i>
          <div class="input_button" v-if="isLinkShow">
            <el-input
              v-model="editTalentInfo.activityLink"
              style="width: 50%"
            />
            <el-button
              type="primary"
              style="height: 40px; margin-left: 24px"
              @click="isLinkShow = false"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="所在地区:" label-width="100px">
          <cascader-area
            :country="editTalentInfo.activityCountry"
            :province="editTalentInfo.activityProvince"
            :city="editTalentInfo.activityCity"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <el-form-item label="详细地址:" label-width="100px">
          <el-input
            style="width: 50%"
            v-model="editTalentInfo.address"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动详情:" label-width="100px">
          <el-input
            v-model="editTalentInfo.activityIntro"
            style="width: 50%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动分享图:">
          <UpLoadImage
            @upLoadImgList="lookClickShareImage"
            @upOldLoadImgList="saveClickShareImage"
            :upLoadImgList.sync="shareImage"
            :upOldLoadImgList.sync="shareImageImageLook"
          />
        </el-form-item>
        <el-form-item label="活动海报:">
          <UpLoadImage
            @upLoadImgList="lookClickPoster"
            @upOldLoadImgList="saveClickPoster"
            :upLoadImgList.sync="activityPosters"
            :upOldLoadImgList.sync="activityPostersLook"
          />
        </el-form-item>
        <!-- 附加信息 -->
        <div class="additional">
          <div class="title">附加信息</div>
          <el-form-item label="活动票种:">
            <div
              class="all"
              v-for="(item, index) in activityInformations"
              :key="index + 'a'"
            >
              <div class="content">
                <el-form-item label="票名:" label-width="120px">
                  <el-input
                    v-model="item.ticketName"
                    placeholder="请输入票名"
                    style="width: 50%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="票数:" label-width="120px">
                  <el-input
                    v-model="item.ticketNumber"
                    placeholder="请输入票数"
                    style="width: 50%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="票价:" label-width="120px">
                  <el-input
                    v-model="item.ticketPrice"
                    placeholder="请输入票价"
                    style="width: 50%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="是否单人单张:" label-width="120px">
                  <el-radio v-model="item.isSingle" :label="1">是</el-radio>
                  <el-radio v-model="item.isSingle" :label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="备注:" label-width="120px">
                  <el-input
                    v-model="item.remarks"
                    placeholder="请输入备注"
                    style="width: 80%"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="operation">
                <span style="color: #4e93fb" @click="addLabel(item)">
                  <i class="el-icon-circle-plus-outline"></i>
                </span>
                <span
                  v-if="index > 0"
                  style="color: #ff7575"
                  @click="delLabel(index)"
                >
                  <i class="el-icon-remove-outline"></i>
                </span>
              </div>
            </div>
          </el-form-item>
        </div>

        <!-- 删除的字段 -->
        <!-- <el-form-item label="导入时间:" label-width="100px" v-if="personType">
          <el-input
            style="width: 465px"
            v-model="editTalentInfo.importTime"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="行业类别:"
          prop="currentTechId"
          label-width="100px"
        >
          <dic-checkbox-group
            :code.sync="editTalentInfo.activityIndustry"
            tag="行业"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item
          label="领域类别:"
          prop="currentTechId"
          label-width="100px"
        >
          <dic-checkbox-group
            :code.sync="editTalentInfo.activityField"
            tag="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item
          label="技术类别:"
          prop="currentTechId"
          label-width="100px"
        >
          <dic-checkbox-group
            :code.sync="editTalentInfo.activityTech"
            tag="技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域标签:" v-if="personType">
          <el-input
            v-model="editTalentInfo.wisdomTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业标签:" v-if="personType">
          <el-input
            v-model="editTalentInfo.IndustryTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="技术标签:" v-if="personType">
          <el-input
            v-model="editTalentInfo.aiTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 28 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词：" v-if="personType">
          <el-input
            v-model="editTalentInfo.antistop"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 20 }"
          ></el-input>
        </el-form-item>
       -->
      </el-form>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
    </div>
    <!-- <div v-if="personType" class="bottom">
      <el-button @click="saveTalents()" type="primary">确定</el-button>
      <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
// import { updateActivityOne } from "@/api/activity";
// import UpLoadImage from "../../uploadImage";
import UpLoadImage from "../../../../Product/UpLoadImage";
// import UploadImage from "../../../../Exercise/ActiveData/uploadImage.vue";
import DicCheckboxGroupTalent from "@/components/DicCheckboxGroupTalent.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import CascaderArea from "@/components/CascaderArea.vue";
// import DicCheckboxGroupMy from "@/components/DicCheckboxGroup.vue";
export default {
  components: {
    UpLoadImage,
    CascaderArea,
    DicRadioGroup,
    DicCheckboxGroupTalent,
    DicCheckboxGroup,
  },
  watch: {
    "editTalentInfo.id": {
      handler(newVal) {
        if (this.editTalentInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    if (!this.personType) {
      this.isShow = true;
    }
  },
  data() {
    return {
      activityImage: [],
      activityImageLook: [],
      shareImage: [],
      shareImageImageLook: [],
      activityPosters: [],
      activityPostersLook: [],
      isLinkShow: false,
      restaurants: [],
      isShow: false,
      imageUrl: "",
      caseDataInfo: {
        imgLook: "",
        simgpathImg: "",
        list: [],
      },
      ruleFormrules: {
        activityName: [
          { required: true, message: "请填写活动名称", trigger: "change" },
        ],
        beginTime: [
          { required: true, message: "请选择活动时间", trigger: "change" },
        ],
        activityBrief: [
          { required: true, message: "请填写一句话描述", trigger: "change" },
        ],
        // activityType: [
        //   { required: true, message: "请选择活动类别", trigger: "change" },
        // ],
        // costCategories: [
        //   { required: true, message: "请选择费用类别", trigger: "change" },
        // ],
        // activityMode: [
        //   { required: true, message: "请选择活动方式", trigger: "change" },
        // ],
      },
      radio: "", //活动类别（临时）
      activityInformations: [
        {
          ticketName: "", //票名
          ticketNumber: "", //票数
          ticketPrice: "", //票价
          isSingle: "", //是否单人单张
          remarks: "", //备注
          activityId: "", //主键id (基础信息的id)
          id: "", // 附加信息id
        },
      ], //附加信息
    };
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    personType: {
      type: String,
    },
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    demandType() {
      return this.$route.query.demandType;
    },
  },
  methods: {
    // 添加活动票种
    addLabel(item) {
      this.activityInformations.push({
        ticketName: "", //票名
        ticketNumber: "", //票数
        ticketPrice: "", //票价
        isSingle: "", //是否单人单张
        remarks: "", //备注
        activityId: "", //主键id (基础信息的id)
        id: "", // 附加信息id
      });
    },
    // 删除活动票种
    delLabel(index) {
      this.activityInformations.splice(index, 1);
    },

    // -------------------------------------------

    // 保存企业人才信息
    // async saveTalents() {
    //   Object.assign(this.editTalentInfo, {
    //     Authorization: this.Authorization,
    //   });
    //   if (this.editTalentInfo.industryChildrenId) {
    //     this.editTalentInfo.activityIndustry =
    //       this.editTalentInfo.activityIndustry.concat(
    //         this.editTalentInfo.industryChildrenId
    //       );
    //   }
    //   if (this.editTalentInfo.techChildrenId) {
    //     this.editTalentInfo.activityTech =
    //       this.editTalentInfo.activityTech.concat(
    //         this.editTalentInfo.techChildrenId
    //       );
    //   }
    //   if (this.editTalentInfo.fieldChildrenId) {
    //     this.editTalentInfo.activityField =
    //       this.editTalentInfo.activityField.concat(
    //         this.editTalentInfo.fieldChildrenId
    //       );
    //   }
    //   const res = await updateActivityOne(this.editTalentInfo);
    //   if (res.code === 200) {
    //     if (this.demandType) {
    //       this.$router.go(-1);
    //     } else {
    //       this.$router.go(-1);
    //     }
    //     this.$message.success("编辑成功");
    //   } else {
    //     this.$message.error("编辑失败");
    //   }
    //   sessionStorage.removeItem("poster");
    // },
    // 取消
    // cancelItem() {
    //   if (this.demandType) {
    //     this.$router.go(-1);
    //   } else {
    //     this.$router.go(-1);
    //   }
    //   sessionStorage.removeItem("poster");
    // },
    searchCountry(val) {
      this.editTalentInfo.activityCountry = val;
    },
    searchProvince(val) {
      this.editTalentInfo.activityProvince = val;
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.editTalentInfo.activityCity = val;
      }
    },
    // 活动图片
    lookClick(val) {
      this.activityImage = val;
    },
    saveClick(val) {
      this.activityImageLook = val;
    },
    // 活动分享图
    lookClickShareImage(val) {
      this.shareImage = val;
    },
    saveClickShareImage(val) {
      this.shareImageImageLook = val;
    },
    lookClickPoster(val) {
      this.activityPosters = val;
    },
    saveClickPoster(val) {
      this.activityPostersLook = val;
    },
    //
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.editTalentInfo.fieldChildrenId = val;
          break;

        case "技术":
          this.editTalentInfo.techChildrenId = val;
          break;

        case "行业":
          this.editTalentInfo.industryChildrenId = val;
          break;

        case "活动":
          this.editTalentInfo.activityType = val;
          break;
        default:
          break;
      }
    },

    // 单位名称弹出框
    // async getCompanyList(){
    //   const res = await samrtFind();
    // }
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("personMessage")[0].style.height =
        document.body.clientHeight - 245 + "px";
    }
  },
};
</script>

<style lang="less" scoped>
// /deep/ .el-input {
//   width: 450px;
// }
.personMessage {
  font-family: PingFang SC;
  // 蓝色圆角化
  margin-bottom: 6px;
  // height: 560px;
  overflow: auto;
  .el-select {
    input::-webkit-input-placeholder {
      color: #1f212b;
    }
    //input::-moz-input-placeholder {
    //  color: #1f212b;
    //}
    input::-moz-placeholder {
      color: #1f212b; /* Firefox 特定样式 */
    }
    input::-ms-input-placeholder {
      color: #1f212b;
    }
    margin-right: 8px;
  }
  .el-button {
    padding: 12px 38px;
  }
  /deep/ .el-textarea__inner {
    height: 100%;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
// 规定图片大小
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 148px;
  height: 148px;
}
// 活动类别盒子
/deep/.el-checkbox-group {
  width: 280px;
  background: #e3e2e2;
  border-radius: 5px;
  padding-left: 10px;
  box-sizing: border-box;
}
// 操作添加-删除的样式
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
// 附加信息样式
.additional {
  padding-left: 2%;
  margin-top: 40px;
  .title {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #146aff;
    margin-bottom: 20px;
  }
  .all {
    display: flex;
    .content {
      width: 599px;
      height: 270px;
      padding: 8px 0px;
      border-radius: 2px;
      border: 1px dashed #c4c4c4;
      /deep/.el-form-item {
        margin-top: 2%;
      }
    }
    .operation {
      margin-left: 10px;
    }
  }
}
</style>
