<template>
  <div>
    <div class="index patentOne">
      <div style="margin-bottom: 10px">
        <el-button type="primary" @click="openAdd()">+添加嘉宾</el-button>
      </div>
      <el-table
        class="ly_table"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
        :data="form2"
      >
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column prop="guestImgLook" label="嘉宾照片">
          <template v-slot="{ row }">
            <div style="cursor: pointer" v-if="row.guestImgLook">
              <el-image :src="row.guestImgLook" class="headerImg" />
            </div>
            <div style="cursor: pointer" v-else>
              <div v-if="row.guestSex == '男'">
                <el-image
                  :src="require('@/assets/img/male.png')"
                  class="headerImg"
                />
              </div>
              <div v-else>
                <el-image
                  :src="require('@/assets/img/female.png')"
                  class="headerImg"
                />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="guestName" label="嘉宾名称"></el-table-column>
        <el-table-column
          prop="guestInstitution"
          label="嘉宾所在机构"
        ></el-table-column>
        <el-table-column
          prop="guestIdentity"
          label="嘉宾职位"
        ></el-table-column>
        <el-table-column prop="guestIntro" label="嘉宾简介">
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.guestIntro"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.guestIntro !== null &&
                      scope.row.guestIntro !== ''
                    "
                  >
                    {{ scope.row.guestIntro.substring(0, 20) }}
                    <span v-if="scope.row.guestIntro.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <button-table
              @buttonClick="openEdit(scope.row, scope.$index)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteEx(scope.row, scope.$index)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="personType"
      >
        <p>创建人：{{ editTalentInfo.createName }}</p>
        <p>修改人：{{ editTalentInfo.updateName }}</p>
        <p>更新时间：{{ editTalentInfo.updateTime }}</p>
        <p>收录时间：{{ editTalentInfo.createTime }}</p>
      </div>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-if="personType"
      />
      <el-dialog
        title="添加嘉宾"
        :visible.sync="dialogFormVisible"
        center
        @close="handleClose"
        width="50%"
      >
        <el-form :model="form" :rules="formRules" ref="ruleForm">
          <el-form-item label="嘉宾名称:" label-width="120px" prop="guestName">
            <el-input v-model="form.guestName" style="width: 430px"></el-input>
          </el-form-item>
          <el-form-item label="嘉宾性别:" label-width="120px" prop="guestSex">
            <el-radio-group v-model="form.guestSex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="嘉宾照片:" label-width="120px" prop="guestImg">
            <upload-image
              :imageUrl.sync="form.guestImgLook"
              :saveUrl.sync="form.guestImg"
              :upLoadFile="upLoadFile"
            />
          </el-form-item>
          <el-form-item
            label="所在机构:"
            label-width="120px"
            prop="guestInstitution"
          >
            <el-input
              v-model="form.guestInstitution"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="嘉宾职位:"
            label-width="120px"
            prop="guestIdentity"
          >
            <el-input
              v-model="form.guestIdentity"
              style="width: 430px"
            ></el-input> </el-form-item
          ><el-form-item
            label="嘉宾简介:"
            label-width="120px"
            prop="guestIntro"
          >
            <el-input
              v-model="form.guestIntro"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="识别结果:" label-width="120px" v-if="options">
            <activity-result :options="options" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addPersonEx('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="编辑专利"
        :visible.sync="dialogFormVisible1"
        center
        @close="handleClose"
        width="50%"
      >
        <el-form :model="editform" :rules="formRules" ref="ruleForms">
          <el-form-item label="嘉宾名称:" label-width="120px" prop="guestName">
            <el-input
              v-model="editform.guestName"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item label="嘉宾性别:" label-width="120px" prop="guestSex">
            <el-radio-group v-model="editform.guestSex">
              <el-radio label="男">男</el-radio>
              <el-radio label="女">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="嘉宾照片:" label-width="120px" prop="guestImg">
            <upload-image
              :imageUrl.sync="editform.guestImgLook"
              :saveUrl.sync="editform.guestImg"
              :upLoadFile="upLoadFile"
            />
          </el-form-item>
          <el-form-item
            label="所在机构:"
            label-width="120px"
            prop="guestInstitution"
          >
            <el-input
              v-model="editform.guestInstitution"
              style="width: 430px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="嘉宾职位:"
            label-width="120px"
            prop="guestIdentity"
          >
            <el-input
              v-model="editform.guestIdentity"
              style="width: 430px"
            ></el-input> </el-form-item
          ><el-form-item
            label="嘉宾简介:"
            label-width="120px"
            prop="guestIntro"
          >
            <el-input
              v-model="editform.guestIntro"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 20 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="识别结果:" label-width="120px" v-if="options">
            <activity-result :options="options" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editEx('ruleForms')"
            >确 定</el-button
          >
          <el-button @click="cancelEx('ruleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- <div class="bottom" v-if="personType">
        <el-button @click="cancelItem" type="primary">确定</el-button>
        <el-button type="info" @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import activityResult from "@/components/activityResult.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import {
  getGuest,
  addGuest,
  updateGuest,
  deleteGuest,
} from "@/api/activity.js";
import { pagination } from "@/mixins/pagination";
import UploadImage from "@/components/UploadImage.vue";
import { time } from "../../../../NumberProject/projectData/mixins/time";
export default {
  mixins: [pagination],
  components: {
    ButtonTable,
    UploadImage,
    activityResult,
  },
  data() {
    return {
      index: 0,
      form: {
        guestImg: null, //嘉宾照片
        guestImgLook: null, //嘉宾显示照片
        guestName: "", //嘉宾名称
        guestSex: "", //嘉宾性别
        guestInstitution: "", //嘉宾所在机构
        guestIdentity: "", //嘉宾职位
        guestIntro: "", //嘉宾简介
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
      formRules: {
        guestName: [
          { required: true, message: "请输入嘉宾名称", trigger: "blur" },
        ],
        guestSex: [
          { required: true, message: "请填写嘉宾性别", trigger: "blur" },
        ],
        guestInstitution: [
          { required: true, message: "请输入嘉宾所在机构", trigger: "blur" },
        ],
      },
      queryInfo: {
        id: "", //人才编号
        pageSize: 10,
        pageNum: 1,
      },
      // total: 0,
      form2: [],
      options: [],
      upLoadFile: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      editform: {
        guestImg: null, //嘉宾照片
        guestImgLook: null, //嘉宾显示照片
        guestName: "", //嘉宾名称
        guestSex: "", //嘉宾性别
        guestInstitution: "", //嘉宾所在机构
        guestIdentity: "", //嘉宾职位
        guestIntro: "", //嘉宾简介
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      },
    };
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },
  props: {
    editTalentInfo: {
      type: Object,
    },
    // 编辑数据的唯一id
    personData: {
      type: String,
    },
    // 0 新增 1 编辑
    personType: {
      type: String,
    },
    // 新增数据的唯一id
    activityId: {
      type: Number,
    },
  },
  created() {
    if (this.personType) {
      this.search();
    }
    this.options = JSON.parse(sessionStorage.getItem("poster"));
  },
  methods: {
    // 清空表单
    deleteForm() {
      this.form = {
        guestImg: null, //嘉宾照片
        guestImgLook: null, //嘉宾显示照片
        guestName: "", //嘉宾名称
        guestSex: "", //嘉宾性别
        guestInstitution: "", //嘉宾所在机构
        guestIdentity: "", //嘉宾职位
        guestIntro: "", //嘉宾简介
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      };
      this.editform = {
        guestImg: null, //嘉宾照片
        guestImgLook: null, //嘉宾显示照片
        guestName: "", //嘉宾名称
        guestSex: "", //嘉宾性别
        guestInstitution: "", //嘉宾所在机构
        guestIdentity: "", //嘉宾职位
        guestIntro: "", //嘉宾简介
        createTime: "", //创建时间
        updateName: "", //修改人名字
        updateTime: "", //修改时间
      };
      this.dialogFormVisible = false;
      this.dialogFormVisible1 = false;
    },
    // 查询
    async search() {
      if (this.personType || this.activityId) {
        const dataPerson = Number(this.personData);
        this.queryInfo.id = dataPerson ? dataPerson : this.activityId;
      }
      const res = await getGuest(this.queryInfo);
      if (res.code === 200) {
        this.form2 = res.data.list;
        this.total = res.data.total;
      }
    },
    // 添加工作经历
    addPersonEx(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const dataPerson = Number(this.personData);
          if (this.personType || this.activityId) {
            Object.assign(this.form, {
              Authorization: this.Authorization,
              activityId: dataPerson ? dataPerson : this.activityId,
            });
            const res = await addGuest(this.form);
            if (res.code === 200) {
              this.$message.success("新增成功");
              this.deleteForm();
              this.search();
            }
          } else {
            var data = this.form;
            this.form2.push(data);
            this.deleteForm();
          }
          this.dialogFormVisible = false;
        }
      });
    },
    // 删除工作经历
    async deleteEx(data, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          if (this.personType || this.activityId) {
            const deleteForm = {
              guestId: data.id,
              activityId: this.personData ? this.personData : this.activityId,
            };
            const res = await deleteGuest(deleteForm);
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.search();
            }
          } else {
            this.form2.splice(index, 1);
          }
        }
      );
    },
    // 打开编辑界面
    openEdit(data, index1) {
      this.options = JSON.parse(sessionStorage.getItem("poster"));
      this.index = index1;
      this.dialogFormVisible1 = true;
      this.editform = JSON.parse(JSON.stringify(data));
      Object.assign(this.editform, {
        Authorization: this.Authorization,
      });
    },
    // 修改工作经历
    editEx(formName, index) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.personType || this.activityId) {
            const res = await updateGuest(this.editform);
            if (res.code === 200) {
              this.$message.success("修改成功");
              this.deleteForm();
              this.search();
            }
          } else {
            this.form2[this.index] = this.editform;
            this.dialogFormVisible1 = false;
          }
        }
      });
    },
    cancelEx(formName) {
      this.dialogFormVisible1 = false;
      this.$refs[formName].resetFields();
    },
    handleClose() {
      this.deleteForm();
      this.upLoadFile = [];
    },
    cancelItem() {
      this.$router.go(-1);
    },
    openAdd() {
      this.dialogFormVisible = true;
      this.options = JSON.parse(sessionStorage.getItem("poster"));
    },
  },
  mounted() {
    if (this.personType) {
      document.getElementsByClassName("patentOne")[0].style.height =
        document.body.clientHeight - 185 + "px";
    }
  },
};
</script>

<style scoped lang="less">
.el-input__inner {
  width: 200px;
}
.el-button {
  padding: 12px 15px;
}
/deep/.el-dialog {
  width: 645px;
}
.patentOne {
  // height: 560px;
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    padding: 8px 10px;
    margin: 0 20px;
  }
}
</style>
