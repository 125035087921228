<template>
  <div class="mainPoster">
    <el-card>
      <div class="content">
        <div class="topTip">
          <span class="yuanjiao"></span>
          <span style="color: #363e4d; font-weight: 600" class="basicMessage"
            >活动海报</span
          >
        </div>
        <div
          class="poster"
          v-loading="loading"
          element-loading-text="解析中，请稍后"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <div class="brn_input">
            <div style="margin-right: 10px">
              <el-upload
                action
                ref="my-upload"
                :before-upload="beforeAvatarUpload"
                :http-request="upload"
                :multiple="false"
                :show-file-list="false"
              >
                <el-button
                  type="button"
                  icon="el-icon-document-copy"
                ></el-button>
              </el-upload>
            </div>
            <div style="margin-right: 10px">
              <el-upload
                action
                ref="my-upload"
                :before-upload="beforeAvatarUpload"
                :http-request="upload1"
                :multiple="false"
                :show-file-list="false"
              >
                <el-button
                  type="button"
                  icon="el-icon-picture-outline"
                ></el-button>
              </el-upload>
            </div>
            <div>
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="deleteImg"
              ></el-button>
            </div>
          </div>
          <div class="topLeft"></div>
          <div class="topRight"></div>
          <div class="bottomLeft"></div>
          <div class="bottomRight"></div>
          <div class="picture">
            <img :src="img" alt="" />
          </div>
        </div>
        <div class="buttonSearch">
          <!-- <el-button>文字识别</el-button> -->
          <!-- <el-button>人脸识别</el-button> -->
        </div>
        <div class="posterMessage">
          <div class="topLeft"></div>
          <div class="topRight"></div>
          <div class="bottomLeft"></div>
          <div class="bottomRight"></div>
          <!-- 识别结果 -->
          <div>
            <p><span>识别结果</span></p>
          </div>
          <div class="CharacterRecognition" v-if="characterRecognition">
            <p v-for="(item, index) in form" :key="index">{{ item }}</p>
          </div>
          <div class="CharacterRecognition" v-else>
            <div
              v-for="(item, index) in form"
              :key="index"
              class="CharacterRecognitionPhoto"
              @click="downLoad(item)"
            >
              <img :src="item" style="max-width: 70%" />
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { recongnizerImg, operateImage } from "@/api/poster.js";
import uploadFile from "@/components/UploadFile";
export default {
  data() {
    return {
      form: [],
      img: "",
      loading: false,
      characterRecognition: false,
    };
  },
  props: {
    posterData: {
      type: Object,
    },
  },
  created() {
    this.img = this.posterData.activityPosterUrl;
    this.form = this.posterData.activityPosterText;
  },
  watch: {
    // 监听活动海报和识别
    "posterData.activityPosterUrl": {
      handler(newVal, oldVal) {
        this.$set(this, "img", newVal);
      },
      immediate: true,
    },
    // 监听活动海报和识别
    "posterData.activityPosterText": {
      handler(newVal, oldVal) {
        this.$set(this, "form", newVal);
        if (this.form.length > 1) {
          this.characterRecognition = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    beforeAvatarUpload(file) {
      let fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = fileName === "jpg";
      const extension2 = fileName === "png";
      const extension3 = fileName === "jpeg";
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是jpg、png、jpeg格式!",
          type: "warning",
        });
        // this.clearFiles();
        this.dataForm.fileString = "";
      }
    },
    //文件上传接口
    async upload(params) {
      this.characterRecognition = true;
      this.loading = true;
      const form = new FormData();
      form.append("file", params.file);
      const res = await recongnizerImg(form);
      if (res.data.advertiseOCRResponse) {
        this.form = res.data.advertiseOCRResponse.textDetections.map((item) => {
          return item.detectedText;
        });
        this.img = res.data.urlList[0];
        sessionStorage.setItem("poster", JSON.stringify(this.form));
        const data = {
          activityPosterUrl: this.img,
          activityPosterText: this.form,
        };
        this.$emit("activityPosterUrl", data);
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    async upload1(params) {
      this.characterRecognition = false;
      this.loading = true;
      const form = new FormData();
      form.append("file", params.file);
      const res = await operateImage(form);
      if (res.code == 200) {
        this.form = res.data.arrayList;
        this.img = res.data.fileUrl[0];
        // sessionStorage.setItem("poster", JSON.stringify(this.form));
        const data = {
          activityPosterUrl: this.img,
          activityPosterText: this.form,
        };
        this.$emit("activityPosterUrl", data);
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    deleteImg() {
      (this.img = ""), (this.form = "");
    },
    downLoad(item) {
      window.open(item);
      // let image = new Image();
      // // 解决跨域 Canvas 污染问题
      // image.setAttribute("crossOrigin", "anonymous");
      // image.src = item;
      // image.onload = function() {
      //   let canvas = document.createElement("canvas");
      //   canvas.width = image.width;
      //   canvas.height = image.height;
      //   let context = canvas.getContext("2d");
      //   context.drawImage(image, 0, 0, image.width, image.height);
      //   let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      //   let a = document.createElement("a"); // 生成一个a元素
      //   let event = new MouseEvent("click"); // 创建一个单击事件
      //   a.download = item; // 设置图片名称
      //   a.href = url; // 将生成的URL设置为a.href属性
      //   a.dispatchEvent(event); // 触发a的单击事件
      // };
    },
  },
  mounted() {
    document.getElementsByClassName("mainPoster")[0].style.height =
      document.body.clientHeight - 132 + "px";
  },
};
</script>

<style lang="less" scoped>
.CharacterRecognitionPhoto {
  text-align: center;
  padding: 5px;
  cursor: pointer;
}
.content {
  display: flex;
  // width: 100%;
  flex-direction: column;
  // justify-content: center;
  height: 100%;
  // width: 20%;
  // overflow: auto;
  .poster {
    width: 100%;
    // flex: 1;
    height: 50%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    position: relative;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    .picture {
      width: 95%;
      height: 97%;
      padding-top: 2%;
      overflow: auto;
      img {
        width: 100%;
      }
    }
  }
  .posterMessage {
    width: 100%;
    height: 40%;
    // flex: 1;
    // height: 300px;
    // overflow: auto;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    position: relative;
    margin-top: 2%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    p {
      color: #1f212b;
      font-size: 15px;
      padding: 10px 0 2px 10px;
      border-bottom: 1px solid #dbdbdb;
      margin: 0 5px 0 5px;
      span {
        border-bottom: 2px solid #1e9fff;
      }
    }
    .CharacterRecognition {
      // height: 100%;
      overflow: auto;
      // flex: 1;
    }
  }
}
.topTip {
  margin-top: 10px;
  margin-bottom: 5px;
  .yuanjiao {
    border: 2px solid #1e9fff;
    height: 20px;
    background-color: #1e9fff;
    border-radius: 5px;
  }
  .basicMessage {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 9px;
  }
}
/deep/ .el-card {
  height: 100%;
  // width: 100%;
}
/deep/ .el-card__body {
  height: 100%;
  // width: 100%;
}
// .buttonSearch{
//   flex: 1;
// }
// div圆角
.topLeft {
  width: 10px;
  height: 10px;
  border-top: 1px solid #1e9fff;
  border-left: 1px solid #1e9fff;
  border-top-left-radius: 5px;
  position: absolute;
  top: -1px;
  left: -1px;
}
.topRight {
  width: 10px;
  height: 10px;
  border-top: 1px solid #1e9fff;
  border-right: 1px solid #1e9fff;
  border-top-right-radius: 5px;
  position: absolute;
  top: -1px;
  right: -1px;
}
.bottomLeft {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #1e9fff;
  border-left: 1px solid #1e9fff;
  border-bottom-left-radius: 5px;
  position: absolute;
  bottom: -1px;
  left: -1px;
}
.bottomRight {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #1e9fff;
  border-right: 1px solid #1e9fff;
  border-bottom-right-radius: 5px;
  position: absolute;
  bottom: -1px;
  right: -1px;
}

// 编辑海报
.brn_input {
  position: absolute;
  top: 10px;
  right: 8%;
  display: flex;
  /deep/ .el-button {
    background-color: #fff;
    color: #363e4d;
    border: none;
    border-radius: 50%;
    padding: 0;
    width: 32px;
    height: 32px;
    i {
      font-size: 20px;
    }
  }
}

::-webkit-scrollbar {
  width: 12px !important;
  height: 8px;
  background-color: #fff;
  // color: #363e4d;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
}
.mainPoster {
  width: 300px;
}
</style>
